import React from "react";
import Link from "../elements/Link";

const Footer = () => {
    return (
        <footer className="footer text-alt">
            <div className="footer-group-1 footer-group">
                <ul className="socials">
                    <li><img src="/images/icons/facebook.svg" alt="facebook" /></li>
                    <li>
                        <a href="https://www.instagram.com/rrbdanceandtalent" target="_blank">
                            <img src="/images/icons/instagram.svg" alt="RRB Dance Instagram profile" />
                        </a>
                    </li>
                </ul>
                <ul className="studio-links">
                    <li><Link href="/studio-policy">Studio Policy</Link></li>
                    <li><Link href="/terms-and-conditions">Terms and Conditions</Link></li>
                    {/* <li><Link href="/privacy-policy">Privacy Policy</Link></li> */}
                </ul>
            </div>
            <div className="footer-group-2 footer-group">
                <p className="copyright">&copy; RRB Dance & Talent</p>
                <p className="developer">Designed / Developed by <nobr><a href="http://jennynt.com" target="_blank">Jenny Neal-Teekasingh</a></nobr></p>
            </div>
        </footer>
    );
};

export default Footer;