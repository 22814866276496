// libraries
import { useEffect, useState } from 'react';

// constants
import routes from '../constants/routes';

const routePaths = [];
routes.forEach(route => routePaths.push(route.path));

/**
 * @function Route
 * @description Functional component that adds location to the browser history and changes route
 * @returns {JSX} Markup for navigation component
 */
const Route = ({ path, children }) => {
    // state to track URL and force component to re-render on change
    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    useEffect(() => {
        // define callback as separate function so it can be removed later with cleanup function
        const onLocationChange = () => {
            setCurrentPath(window.location.pathname);
        }

        window.addEventListener('popstate', onLocationChange);

        // clean up the event listener
        return () => {
            window.removeEventListener('popstate', onLocationChange)
        };
    }, []);

    // if route doesnt exist (bad url route) send back to home page
    let query;
    if (!routePaths.includes(currentPath)) {
        query = '/';
    } else {
        query = currentPath;
    }
    return query === path ? children : null
};
  
export default Route;
