import React from "react";

const ParentLogin = () => {
    return (
        <div className="parent-login">
            <section>
                <h1>Parent Login</h1>
                <p>Information coming soon...</p>
            </section>
        </div>
    );
};

export default ParentLogin;