import React, {useEffect} from "react";

const About = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className="about">
            <section>
                <h1>About Us / History / Teachers</h1>
                <p className="callout">For many young performers, dance is more than a hobby; it's an art form, a heartbeat, and a lifelong passion.</p>
                <p>Rooted in choreography and performance-based dance, RRB Dance & Talent is a supportive environment dedicated to providing students with the skills and techniques needed to assist them not only along the road to performing but along the path to becoming the dancers we know they can be.</p>
                <p>At RRB Dance & Talent, we believe creating strong, resilient dancers begins with reminding them that they are humans first and performers second. By instructing today's youth to lead with their hearts, we hope to empower them to take on challenges in the studio &mdash; and in life &mdash; with kindness, respect, and integrity.</p>
                <p>As we continue to grow, we hope to bring unique dance opportunities directly to students with help from an in-house talent agency.</p>
            </section>

            <section>
                <img src="http://fpoimg.com/348x348?text=FPO&bg_color=45BDCD&text_color=FFFFFF" alt="Robin R. Ball" />
                <h2>Robin R. Ball CEO / Director</h2> 
                <p>After tragically losing her best friend and fellow dancer at just 15 years old, Robin Ball felt called to not only instruct children and teens in various dance styles but encourage them to chase their dreams, practice kindness, and live each day to the fullest.</p>
                <p>With over 3 decades of experience in choreography, Robin is a trusted mentor who is proud to do her part in shaping today's dancers.</p>
            </section>

            <section>
                <img src="http://fpoimg.com/348x348?text=FPO&bg_color=45BDCD&text_color=FFFFFF" alt="Tequoia Peebles" />
                <h2>Miss Tee Studio Manager</h2>
                <p>Sixteen-year-old Tequoia (Miss Tee) Peebles was a student at The Philadelphia High School of the Creative and Performing Arts when RRB Dance & Talent founder Robin Ball was looking for a responsible dancer to assist her in teaching hip-hop classes.</p>
                <p>Although she was only a sophomore, Tequoia came highly recommended by the dance department's director, and she quickly proved to be a talented dancer and instructor as she passionately lent her knowledge, support, and guidance to budding young performers.</p>
                <p>Before joining the RRB Dance & Talent team, Tequoia received extensive training in ballet, tap, jazz, hip hop, modern dance, and gymnastics/tumbling with L&L Dance Studio and The Freedom Theater.</p>
                <p>Tequoia has worked as a studio instructor and administrative assistant for nearly 20 years, sharing her beautiful personality, advice, and organizational skills with students, parents, and co-teachers!</p>
                <p>When she isn't at the studio, Tequoia is a loving mom dancing through each new day alongside her daughter, Brooklyn, and her son, Avery.</p>
            </section>
        </div>
    );
};

export default About;