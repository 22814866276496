import React, {useEffect} from "react";

const StudioPolicy = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className="studio-policy">
            <h1>Studio Conduct Policy</h1>
            <p>All RRB Dance & Talent activities are approached with respect, integrity and professionalism. We are committed to providing a safe, welcoming and nurturing environment that seeks not only to instill a love of dance in our students, but to develop important life skills as well - independence, commitment, reliability and teamwork.</p>
            <p>To ensure a happy and positive experience for all involved, it is important that students, parents (including carers or legal guardians), extended friends and family members and staff understand and comply with below.</p>
            <p>RRB Dance & Talent reserves the right to suspend or dismiss any student whose conduct or attitude, or whose parent's conduct or attitude, (including carers or legal guardians), extended friends and family, staff or volunteers is inappropriate,  disrespectful and aggressive. </p>
            <p>The students of RRB Dance & Talent are permitted to treat everyone involved with respect. Students must always greet RRB Dance faculty pleasantly and address them as Mr., Miss.</p>
            <p>RRB Dance & Talent classes are about individual dance accomplishments, not body types.  It is our privilege to teach every eager student who comes to class, regardless of size, shape or ability.</p>
            <p>No smoking permitted anywhere in the building or on the grounds of the building.</p>
            <p>Conduct such as physical intimidation or injury, verbal harassment, sexual harassment, bullying or coercion, including by cell phone, on the internet, or in social media. Will result in immediate dismissal.</p>
        </div>
    );
};

export default StudioPolicy;