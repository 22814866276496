import React, {useEffect} from "react";

const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className="terms-and-conditions">
            <h1>Terms And Conditions</h1>
            <p>This Terms & Conditions and Privacy Policy governs the manner in which RRB DANCE & TALENT collects, uses, maintains and discloses information collected from users (each, a “User”) of the www.rrbdanceandtalent.com website (“Site”). This privacy policy applies to the Site and all products and services offered by RRB DANCE & TALENT.</p>
            <p>By registering your child via the RRB DANCE & TALENT website you are agreeing to our Terms & Conditions. You agree to that you have read these terms and conditions and waive any right to claim against RRB DANCE & TALENT Owners, staff, and teachers in the event of injury or loss of personal items. You agree that you understand your child's photo may be used on the RRB DANCE & TALENT’s parent portal, social media sites, and various other marketing materials. If you do not agree, you must fill out the following form and return to our office.</p>
        </div>
    );
};

export default TermsAndConditions;