import React, { useEffect, useRef } from "react";

const TopQuote = () => {
    return (
        <div className="top-quote">
            <figure>
                <blockquote>
                    <span>&#8220;</span>
                        For many young performers, dance is more than a hobby; it's an art form, a heartbeat, and a lifelong passion.
                    <span>&#8221;</span>
                </blockquote>
                <figcaption>-Robin R. Ball, CEO / Director</figcaption>
            </figure>
        </div>
    );
};

export default TopQuote;