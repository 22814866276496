import React, {useEffect} from "react";

const StyleGuide = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div>
            <h1>Style Guide</h1>
            <hr/>
            <h1>This is a Heading: H1</h1>
            <h2>This is a secondary Heading: H2</h2>
            <h3>This is a terary Heading: H3</h3>
            <hr/>
            <div>
                <p>Paragraphs have a margin at the bottom of them.</p>
                <p>Paragraphs have a margin at the bottom of them.</p>
            </div>
            <button>Button Text</button><br/><br/>
            <button className="button-alt">Button Text</button><br/><br/>
            <a href="#">Link Text</a>

            <div className="colors">
                <h2>Colors</h2>
                <div className="marquee">
                    <p className="text-alt">$marquee</p>
                </div>
                <div className="marquee-80">
                    <p className="text-alt">$marquee-80</p>
                </div>
                <div className="marquee-50">
                    <p className="text-alt">$marquee-50</p>
                </div>
                <div className="marquee-20">
                    <p className="text-alt">$marquee-20</p>
                </div>
                <div className="spotlight">
                    <p>$spotlight</p>
                </div>
                <div className="spotlight-50">
                    <p>$spotlight-50</p>
                </div>
                <div className="accented">
                    <p className="text-alt">$accented</p>
                </div>

                <div className="darkest">
                    <p className="text-alt">$darkest</p>
                </div>
                <div className="darkest-50">
                    <p className="text-alt">$darkest-50</p>
                </div>
                <div className="central">
                    <p>$central</p>
                </div>
                <div className="brightest">
                    <p>$brightest</p>
                </div>
                <div className="brightest-50">
                    <p>$brightest-50</p>
                </div>
            </div>
        </div>
    );
};

export default StyleGuide;