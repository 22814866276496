import React, { useEffect } from 'react';

const Location = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className="location">
            <h1>Location</h1>
            <address>
                Mountain View Community Center<br/>
                8625 E. Mountain View Road<br/>
                Scottsdale AZ 85258
            </address>

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3324.2847346208237!2d-111.89865492430401!3d33.57195567334151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b752f70e0127f%3A0x20fc4d8d6e76ba00!2sMountain%20View%20Community%20Center!5e0!3m2!1sen!2sus!4v1703130296130!5m2!1sen!2sus"
                width="400"
                height="400"
                style={{ border: 0 }}
                loading="lazy"
                title="Map of Mountain View Community Center"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    );
};

export default Location;