// library
import { useEffect, useState, useRef } from 'react';

// utils
// import throttle from "../utils/throttle";
import screen from '../utils/screenSize';

// components
import TopQuote from "./TopQuote";

// elements
import Link from "../elements/Link";

/**
 * @function Navigation
 * @description Functional component containing navigation markup
 * @returns {JSX} Markup for navigation component
 */
const Navigation = () => {
    const [menuIsActive, setMenuActive] = useState(false);
    const [screenSize, setScreenSize] = useState(screen());
    
    const onMenuHandler = () => {
        setMenuActive(!menuIsActive);
    };

    const closeMenu = () => {
        setMenuActive(false);
    };

    const currentPath = window.location.pathname.replace('/', '');

    const links = [{
        // home: {
        //     label: 'Home',
        //     route: 'home'
        // },
        classes: {
            label: 'Classes',
            route: 'classes'
        },
        talent: {
            label: "Talent",
            route: 'talent'
        },
        location: {
            label: 'Location',
            route: 'location'
        },
        about: {
            label: 'About Us',
            route: 'about'
        },
        parent: {
            label: 'parent login',
            route: 'parent-login'
        },
    }];

    useEffect(() => {
        function updateScreenHandler() {
            closeMenu();
        }

        window.addEventListener('resize', updateScreenHandler);
        return () => {
            window.removeEventListener('resize', updateScreenHandler);
        };
    }, []);

    useEffect(() => {
        if (screenSize === 'small') {
            document.body.className = `${menuIsActive ? 'open-nav': ''}`;
        } else {
            document.body.classList.remove('open-nav');
        }
    }, [menuIsActive, screenSize]);

    useEffect(() => {
        const updateScreenSize = () => {
            setScreenSize(screen());
        };
    
        window.addEventListener('resize', updateScreenSize);
    }, []);



    const logo = useRef(null);
    const mainNav = useRef(null);

    const onScroll = () => {
        if (mainNav) {
            if (window.scrollY > 0) {
                !mainNav.current.classList.contains('pinned') && mainNav.current.classList.add('pinned');
            } else {
                mainNav.current.classList.contains('pinned') && mainNav.current.classList.remove('pinned');
            }
        }
    };

    // const onScrollThrottle = throttle(onScroll, 10);
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
    }, [mainNav]);


    return (
        <nav id="main-nav" className="main-nav" ref={mainNav}>
            <div className="main-nav__wrapper">
                <img src="/images/about/photo3.jpg" className='main-nav--background' alt="dancers" />
                
                <>
                    <div className="main-nav__menu" onClick={onMenuHandler}>
                        <span className="material-symbols-outlined">
                            {menuIsActive ? 'close' : 'menu'}
                        </span>
                        {menuIsActive ? 'close' : 'menu'}
                    </div>
                    <Link href='/' className='main-nav--logo-cta' cleanupFunc={closeMenu}>
                        <img src="/images/rrbdance-logo.svg" 
                        alt="RRB Dance Logo" 
                        className="main-nav--logo" 
                        ref={logo} />
                    </Link>
                    <ul className={`main-nav--links ${menuIsActive ? 'active' : ''}`}>
                        {Object.keys(links[0]).map((link, index) => (
                            <li key={index}>
                                <Link
                                    href={`/${links[0][link].route}`}
                                    cleanupFunc={onMenuHandler}
                                    className={currentPath.startsWith(link) ? 'active' : ''}>
                                    {links[0][link].label}
                                </Link>
                            </li>
                        ))}
                        <li className='nav-logo'><img src="/images/rrbdance-logo.svg"  alt="RRB Dance Logo" /></li>
                    </ul>
                    <TopQuote />
                    <div
                        onClick={closeMenu}
                        className={`overlay-bg ${menuIsActive ? 'active' : ''}`}>
                    </div>
                </>
            </div>
        </nav>
    );
};

export default Navigation;
