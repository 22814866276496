import React, {useEffect} from "react";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <p>Home</p>
    );
};

export default Home;