// libraries
import React from "react";
// import React, { useState, useEffect } from "react";
import './App.css';
import './style/index.scss';

// components
import Route from "./router/Router";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import AboutDisplay from "./components/AboutDisplay";


// constants
import routes from './constants/routes';

function App() {
  // const [message, setMessage] = useState('');

  // useEffect(() => {
  //   fetch("http://localhost:8000/message")
  //   .then((res) => res.json())
  //   .then((data) => setMessage(data.message));
  // }, []);

  return (
    <div className="App">
      <Navigation />
     
      {routes.map((route, index) => {
        let mainClass;
        
        if (route.path === '/' || route.path === '/blog') {
          mainClass = 'full';
        } else if (route.path.startsWith('/blog/')) {
          mainClass = 'blog-article';
        } else if (route.path.startsWith('/studio')) {
          mainClass = 'studio';
        } else {
          mainClass = route.path.replace('/', '');
        }
        
        return (
          <>
            <Route path={route.path} key={index}>

              <div id="content-body">

                  {route.path.startsWith('/about') && <AboutDisplay />}
                  <div className='main-wrapper'>
                    <main className={mainClass}>
                      {route.component}
                    </main>
                  </div>

              </div>

            </Route>
          </>
        )
      })}
      <Footer />
    </div>
  );
}

export default App;
