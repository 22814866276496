import React from "react";

const AboutDisplay = () => {
    return (
        <section className="about-display">
            <img src="/images/about/photo1.jpg" />
            <img src="/images/about/photo2.jpg" />
            <img src="/images/about/photo3.jpg" />
        </section>
    );
};

export default AboutDisplay;