// component pages
import Home from '../pages/Home';
import About from '../pages/About';
import Location from '../pages/Location';
import StyleGuide from '../pages/StyleGuide';
import StudioPolicy from '../pages/StudioPolicy';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions';
import Classes from '../pages/Classes';
import ParentLogin from '../pages/ParentLogin';
import Talent from '../pages/Talent';

/**
 * @constant sitePaths
 * @description Object list of corresponding component and path name as string
 * @returns {Object} Object group of paths for site
 */
const sitePaths = [
    {
      component: <Home/>,
      path: '/'
    },
    {
      component: <About />,
      path: '/about'
    },
    {
      component: <Location />,
      path: '/location'
    },
    {
        component: <StyleGuide />,
        path: '/style-guide'
    },
    {
        component: <PrivacyPolicy />,
        path: '/privacy-policy'
    },
    {
        component: <TermsAndConditions />,
        path: '/terms-and-conditions'
    },
    {
        component: <StudioPolicy />,
        path: '/studio-policy'
    },
    {
      component: <Classes />,
      path: '/classes'
  },
  {
    component: <Talent />,
    path: '/talent'
  },
  {
    component: <ParentLogin />,
    path: '/parent-login'
  },
];

export default sitePaths;
