import React from "react";

const Classes = () => {
    return (
        <div className="classes">
            <section>
                <h1>Classes</h1>
                <p>Information coming soon...</p>
            </section>
        </div>
    );
};

export default Classes;