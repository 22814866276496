import React from "react";

const Talent = () => {
    return (
        <div className="talent">
            <section>
                <h1>Talent</h1>
                <p>Information coming soon...</p>
            </section>
        </div>
    );
};

export default Talent;